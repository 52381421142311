// Set config defaults when creating the instance

import history from "app/history";
import axios from "axios";
import axiosRetry from "axios-retry";
import { DEV_REDIRECT_URI, PROD_REDIRECT_URI } from "constants/appConstants";
import { isLoggedIn, logout } from "slices/loginSlice";
import Swal from "sweetalert2";
import { getChatAuthToken } from "utils/localStorage";
export const vchost = "vc.webnyay.in";

console.log("location1: ", window.location.origin);
console.log("location2: ", window.location.host);
console.log("location3: ", window.location.hostname);

let Url = "";
let linkedin_url = "";

if (
  window.location.host == "app-doc.webnyay.in" ||
  window.location.host == "docs.webnyay.ai" ||
  window.location.host == "docs-app-frontend-prod-test.webnyay.in"
) {
  Url = "https://prod-api-pdf.webnyay.in/";
  // Url = "https://docs-backend-prod-test.webnyay.in/";
} else {
  // Url = "https://dev-api-docs-backend-test.webnyay.in/";
  Url = "https://dev-api-pdf.webnyay.in/";
}

linkedin_url = "https://app.webnyay.in/linkedin-callback";

console.log("Url: ", Url);

// PRODUCTION used when run on production
export const baseUrl = Url;
export const chatHost = "vc.webnyay.in:4000";
export const chatUrl = `https://${chatHost}`;
export const websocketUrl = `wss://${chatHost}/websocket`;
export const linkedinRedirectUrl = linkedin_url;

// LOCAL used when run on local
// export const baseUrl = "http://127.0.0.1:8000/";
// export const chatHost = "127.0.0.1:3000";
// export const chatUrl = `http://${chatHost}`;
// export const websocketUrl = `ws://${chatHost}/websocket`;
// export const linkedinRedirectUrl = "http://localhost:4000/linkedin-callback";

let dropbox_redirect_uri;
if (
  window.location.host == "app-doc.webnyay.in" ||
  window.location.host == "docs.webnyay.ai"
) {
  dropbox_redirect_uri = PROD_REDIRECT_URI;
} else if (window.location.host === "docs-app-frontend-dev-test.webnyay.in") {
  dropbox_redirect_uri = DEV_REDIRECT_URI;
} else {
  dropbox_redirect_uri = "http://localhost:3000/dropbox/authorised";
}

export const REDIRECT_URI = dropbox_redirect_uri;

export const linkedinClientid = "78g07uxwv2qjbw";

export const linkedinOauthurl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedinClientid}&scope=r_liteprofile%20r_emailaddress&state=123456&redirect_uri=${linkedinRedirectUrl}`;

export const instance = axios.create({
  baseURL: baseUrl,
  timeout: 180000,
});

export const chatInstance = axios.create({
  baseURL: chatUrl,
  timeout: 30000,
});

chatInstance.interceptors.request.use(function (config) {
  const { token, userId } = getChatAuthToken();

  if (token) {
    config.headers["X-Auth-Token"] = token;
    config.headers["X-User-Id"] = userId;
  }
  return config;
});

axiosRetry(instance, {
  retries: 5, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 1000; // time interval between retries
  },
  retryCondition: (error) => {
    // console.log(error.response.status);
    // if retry condition is not specified, by default idempotent requests are retried
    return (
      !error.response ||
      (error.response.status >= 501 && error.response.status <= 504)
    );
  },
});

instance.interceptors.request.use(function (config) {
  const token = isLoggedIn();
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

export function errorInterceptor(store) {
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error);
      let { status } = error.request;
      if (status === 0 || status === 404 || status === 500) {
        history.push({ pathname: "/fallback" });
      } else if (status === 401) {
        let timerInterval;
        Swal.fire({
          title: "Session Expired",
          html: "Your session has expired. You will be redirected to login page",
          timer: 2000,
          timerProgressBar: true,
          onBeforeOpen: () => {
            Swal.showLoading();
            timerInterval = setInterval(() => {
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector("b");
                if (b) {
                  b.textContent = Swal.getTimerLeft();
                }
              }
            }, 100);
          },
          onClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            store.dispatch(logout());
            history.push("/login");
          }
        });
      }
      return Promise.reject(error);
    }
  );
}

export default instance;
