export const OBJECT_NOT_FOUND = "object_not_found";
export const FAILED = "failed";
export const NA = "na";
export const APPLICATION_JSON = "application/json";
export const DROPBOX = "Dropbox";
export const GOOGLE_DRIVE = "Google Drive";
export const DROPBOX_APP_KEY = "msd9dyatj5lqvef";
export const DROPBOX_APP_SECRET = "zhysbda45msebkd";
export const DEV_REDIRECT_URI =
  "https://docs-app-frontend-dev-test.webnyay.in/dropbox/authorised";
export const PROD_REDIRECT_URI = "https://docs.webnyay.ai/dropbox/authorised";
export const GENERIC_MSG = `We are currently experiencing an unexpected error. Our team is actively investigating the issue to get things back on track as soon as possible.\nThank you for your patience while we work on resolving this. If you need immediate assistance or have any concerns, please reach out to us at <span style='color: blue'>hello@webnyay.ai</span>.\nIn the meantime, we kindly ask you to click the button below to go back and give it another try.`;
export const LOGIN = "login";
export const VERIFY_PASSWORD = "verify_password";
export const CREATE_USER = "creat_user";
export const VERIFY_EMAIL = "verify_email";
export const SUCCESS = "success";
export const WARNING = "warning";
export const INFO = "info";
export const ERROR = "error";
export const ERROR_TITLE = "Something went wrong!";
export const SUCCESS_TITLE = "Success";
export const WARNING_TITLE = "Warning";
export const INFO_TITLE = "Information";
export const PASSWORD_HELPER_TEXT =
  "Minimum of 6 characters including atleast one upper case alphabet, lower case alphabet, number and special character.";
export const PASSWORD_REGEX =
  /^(?=.{6,15}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
